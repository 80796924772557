<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>运营</a-breadcrumb-item>
              <a-breadcrumb-item>租户管理</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="!visible" class="Enter-top-right"></div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <div
        style="
          background-color: #fff;
          height: 100px;
          margin: 0;
          min-width: 1450px;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '楼栋'"
                  placeholder="请选择楼栋"
                  style="width: 178px"
                  v-model="params['cb.id'].value"
                >
                  <a-select-option v-for="item in buildlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '楼层'"
                  placeholder="请选择楼层"
                  style="width: 178px"
                  v-model="params['cf.id'].value"
                >
                  <a-select-option v-for="item in floorlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '铺位'"
                  placeholder="请选择铺位"
                  style="width: 178px"
                  v-model="params['cs.id'].value"
                >
                  <a-select-option v-for="item in storelist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
                <a-input
                  style="width: 173.5px"
                  v-else
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-layout-content
        style="min-width: 1450px; background: #fff; padding: 24px; margin: 0"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              rowKey="signId"
              :pagination="false"
              :columns="columns"
              :data-source="tenantlist"
              :rowClassName="rowClassName"
            >
              <span slot="status1" slot-scope="text">
                <a-badge
                  :text="text | stateFilter1"
                  :status="text | stateTypeFilter1"
                />
              </span>
              <span slot="status2" slot-scope="text">
                <a-badge
                  :text="text | stateFilter2"
                  :status="text | stateTypeFilter2"
                />
              </span>
              <span slot="status3" slot-scope="text">
                <a-badge
                  :text="text | stateFilter3"
                  :status="text | stateTypeFilter3"
                />
              </span>
              <span slot="status4" slot-scope="text">
                <a-badge
                  :text="text | stateFilter4"
                  :status="text | stateTypeFilter4"
                />
              </span>
              <span slot="status5" slot-scope="text">
                <a-badge
                  :text="text | stateFilter5"
                  :status="text | stateTypeFilter5"
                />
              </span>
              <span slot="startDate" slot-scope="text, record">
                {{ record.startDate }}--{{ record.endDate }}
              </span>
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">详情</a>
                <!-- <a @click="onEdit(record)">进场</a>&nbsp;&nbsp;
                <a @click="onEdit(record)">装修</a>&nbsp;&nbsp;
                <a @click="onEdit(record)">验收</a>&nbsp;&nbsp;
                <a @click="onEdit(record)">开业</a>&nbsp;&nbsp;
                <a @click="onEdit(record)">退租</a> -->
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>租户信息</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-model-item prop="type" class="ant--input" label="客户类型">
            <a-select
              disabled="true"
              v-model="form.client.type"
              style="width: 100%"
            >
              <a-select-option value="0"> 公司 </a-select-option>
              <a-select-option value="1"> 个人 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-show="form.client.type == 1"
            class="ant--input"
            label="姓名"
          >
            <a-input
              disabled="true"
              placeholder="请输入姓名"
              v-model="form.client.name"
            />
          </a-form-model-item>
          <a-form-model-item
            v-show="form.client.type == 0"
            class="ant--input"
            label="公司名"
          >
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入公司名"
              v-model="form.client.name"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="联系地址">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系地址"
              v-model="form.client.name"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="联系电话">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系电话"
              v-model="form.client.content"
            />
          </a-form-model-item>
        </div>
      </a-layout-content>
      <a-layout-content style="background: #fff; margin: 24px 0">
        <div class="content-title">
          <span>合同信息</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-model-item class="ant--input" label="意向合同">
            <a-select
              placeholder="请输入意向合同"
              style="width: 100%"
              disabled="true"
              v-model="form.sign.intentionId"
            >
              <a-select-option v-for="item in intentionlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="合同编号">
            <a-input
              disabled="true"
              placeholder="请输入合同编号"
              v-model="form.sign.code"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="签约人">
            <a-select
              disabled="true"
              placeholder="请选择签约人"
              style="width: 100%"
              v-model="form.sign.signBy"
            >
              <a-select-option v-for="item in userlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="负责人">
            <a-select
              placeholder="请选择负责人"
              style="width: 100%"
              disabled="true"
              v-model="form.sign.belongTo"
            >
              <a-select-option v-for="item in userlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="租期起止">
            <a-range-picker disabled="true" v-model="form.sign.Date">
            </a-range-picker>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="签约日期">
            <a-date-picker
              disabled="true"
              v-model="form.sign.signDate"
              style="width: 100%"
            >
              <template slot="renderExtraFooter"> </template>
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="交房日期">
            <a-date-picker
              disabled="true"
              v-model="form.sign.giveDate"
              style="width: 100%"
            >
              <template slot="renderExtraFooter"> </template>
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="开业日期">
            <a-date-picker
              disabled="true"
              v-model="form.sign.openDate"
              style="width: 100%"
            >
              <template slot="renderExtraFooter"> </template>
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="出租方">
            <a-select
              show-search
              disabled="true"
              style="width: 100%"
              v-model="form.sign.partyAId"
            >
              <a-select-option v-for="it in Enterpriselist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="出租方名称">
            <a-input
              disabled="true"
              placeholder="请输入出租方名称"
              v-model="form.sign.partyAName"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="出租方联系电话">
            <a-input
              disabled="true"
              placeholder="请输入出租方联系电话"
              v-model="form.sign.partyAContact"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="出租方联系地址">
            <a-input
              placeholder="请输入出租方联系地址"
              disabled="true"
              v-model="form.sign.partyAAddress"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="承租方">
            <a-select
              show-search
              disabled="true"
              style="width: 100%"
              v-model="form.sign.partyBId"
            >
              <a-select-option v-for="it in Clientlist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="承租方名称">
            <a-input
              disabled="true"
              placeholder="请输入承租方名称"
              v-model="form.sign.partyBName"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="承租方联系电话">
            <a-input
              disabled="true"
              placeholder="请输入承租方联系电话"
              v-model="form.sign.partyBContact"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="承租方联系地址">
            <a-input
              disabled="true"
              placeholder="请输入承租方联系地址"
              v-model="form.sign.partyBAddress"
            />
          </a-form-model-item>
        </div>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>铺位信息</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <a-form-model-item class="ant--input" label="铺位号">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系地址"
              v-model="form.store.name"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="建筑面积">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系地址"
              v-model="form.store.name"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="使用面积">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系地址"
              v-model="form.store.name"
            />
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="计租面积">
            <a-input
              disabled="true"
              style="margin-top: 4px"
              placeholder="请输入联系地址"
              v-model="form.store.name"
            />
          </a-form-model-item>
        </div>
      </a-layout-content>
      <a-layout-content style="background: #fff; margin-top: 24px"
        ><div class="content-title">
          <span>租赁状态</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-column">
          <div
            class="flex-d-row"
            style="height: 50px; align-items: center; overflow-x: auto"
          >
            <div class="state">进场状态:</div>
            <a-radio-group
              style="margin-right: 15px"
              v-model="inFilestate"
              @change="handlestateChange(0)"
            >
              <a-radio-button value="0"> 未进场 </a-radio-button>
              <a-radio-button value="1"> 进场中 </a-radio-button>
              <a-radio-button value="2"> 已进场 </a-radio-button>
            </a-radio-group>
            <div
              v-for="(item, index) in inFiles"
              :key="item.id"
              class="list-border"
            >
              <div>
                <span>{{ item.paperTypeName }} </span>
                <a style="float: right; margin-right: 6px" :href="item.url"
                  >下载</a
                >
                <a-icon
                  @click="ondelete(0, index, item)"
                  class="delete"
                  type="close"
                />
              </div>
              <span>{{ item.realName }}</span>
            </div>
            <div @click="onopen(0)" class="body-building">添加资料</div>
          </div>
          <div
            class="flex-d-row"
            style="height: 50px; align-items: center; overflow-x: auto"
          >
            <div class="state">装修状态:</div>
            <a-radio-group
              style="margin-right: 15px"
              v-model="decorateFilestate"
              @change="handlestateChange(1)"
            >
              <a-radio-button value="0"> 未装修 </a-radio-button>
              <a-radio-button value="1"> 装修中 </a-radio-button>
              <a-radio-button value="2"> 已装修 </a-radio-button>
            </a-radio-group>
            <div
              v-for="(item, index) in decorateFiles"
              :key="item.id"
              class="list-border"
            >
              <div>
                <span>{{ item.paperTypeName }} </span>
                <a style="float: right; margin-right: 6px" :href="item.url"
                  >下载</a
                >
                <a-icon
                  @click="ondelete(0, index, item)"
                  class="delete"
                  type="close"
                />
              </div>
              <span>{{ item.realName }}</span>
            </div>
            <div @click="onopen(1)" class="body-building">添加资料</div>
          </div>
          <div
            class="flex-d-row"
            style="height: 50px; align-items: center; overflow-x: auto"
          >
            <div class="state">验收状态:</div>
            <a-radio-group
              style="margin-right: 15px"
              v-model="acceptFilestate"
              @change="handlestateChange(2)"
            >
              <a-radio-button value="0"> 未验收 </a-radio-button>
              <a-radio-button value="1"> 验收中 </a-radio-button>
              <a-radio-button value="2"> 已验收 </a-radio-button>
            </a-radio-group>
            <div
              v-for="(item, index) in acceptFiles"
              :key="item.id"
              class="list-border"
            >
              <div>
                <span>{{ item.paperTypeName }} </span>
                <a style="float: right; margin-right: 6px" :href="item.url"
                  >下载</a
                >
                <a-icon
                  @click="ondelete(0, index, item)"
                  class="delete"
                  type="close"
                />
              </div>
              <span>{{ item.realName }}</span>
            </div>
            <div @click="onopen(2)" class="body-building">添加资料</div>
          </div>
          <div
            class="flex-d-row"
            style="height: 50px; align-items: center; overflow-x: auto"
          >
            <div class="state">开业状态:</div>
            <a-radio-group
              style="margin-right: 15px"
              v-model="openFilestate"
              @change="handlestateChange(3)"
            >
              <a-radio-button value="0"> 未开业 </a-radio-button>
              <a-radio-button value="1"> 开业中 </a-radio-button>
              <a-radio-button value="2"> 已开业 </a-radio-button>
            </a-radio-group>
            <div
              v-for="(item, index) in openFiles"
              :key="item.id"
              class="list-border"
            >
              <div>
                <span>{{ item.paperTypeName }} </span>
                <a style="float: right; margin-right: 6px" :href="item.url"
                  >下载</a
                >
                <a-icon
                  @click="ondelete(0, index, item)"
                  class="delete"
                  type="close"
                />
              </div>
              <span>{{ item.realName }}</span>
            </div>
            <div @click="onopen(3)" class="body-building">添加资料</div>
          </div>
          <div
            class="flex-d-row"
            style="height: 50px; align-items: center; overflow-x: auto"
          >
            <div class="state">退租状态:</div>
            <a-radio-group
              style="margin-right: 15px"
              v-model="outFilestate"
              @change="handlestateChange(4)"
            >
              <a-radio-button value="0"> 未退租 </a-radio-button>
              <a-radio-button value="1"> 退租中 </a-radio-button>
              <a-radio-button value="2"> 已退租 </a-radio-button>
            </a-radio-group>
            <div
              v-for="(item, index) in outFiles"
              :key="item.id"
              class="list-border"
            >
              <div>
                <span>{{ item.paperTypeName }} </span>
                <a style="float: right; margin-right: 6px" :href="item.url"
                  >下载</a
                >
                <a-icon
                  @click="ondelete(0, index, item)"
                  class="delete"
                  type="close"
                />
              </div>
              <span>{{ item.realName }}</span>
            </div>
            <div @click="onopen(4)" class="body-building">添加资料</div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-content
        v-if="visible == true"
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
          height: 70px;
          text-align: center;
        "
      >
        <div save-draw>
          <a-button type="primary" @click="onSubmit"> 提交 </a-button>
          <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
        </div>
      </a-layout-content>
    </a-layout>
    <a-modal
      title="上传"
      :visible="filevisible"
      @ok="onfile"
      @cancel="onfileclose"
    >
      <div>
        <a-select
          placeholder="请选择进场状态"
          @change="onparper"
          style="width: 200px"
          v-model="list"
        >
          <a-select-option
            v-for="item in paperTypelist"
            :key="item.id + 'T' + item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-upload
          :file-list="files"
          :multiple="true"
          action=""
          :customRequest="handlefile"
        >
          <a-button :disabled="paperTypeId == '' ? true : false">
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </div>
    </a-modal>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '租户名称',
    dataIndex: 'clientName',
  },
  {
    title: '楼栋名称',
    dataIndex: 'buildingName',
  },
  {
    title: '楼层名称',
    dataIndex: 'floorName',
  },
  {
    title: '铺位名称',
    dataIndex: 'storeName',
  },
  {
    title: '租期起止',
    dataIndex: 'startDate',
    scopedSlots: { customRender: 'startDate' },
  },
  {
    title: '进场状态',
    dataIndex: 'inState',
    scopedSlots: { customRender: 'status1' },
  },
  {
    title: '装修状态',
    dataIndex: 'decorateState',
    scopedSlots: { customRender: 'status2' },
  },
  {
    title: '验收状态',
    dataIndex: 'acceptState',
    scopedSlots: { customRender: 'status3' },
  },
  {
    title: '开业状态',
    dataIndex: 'openState',
    scopedSlots: { customRender: 'status4' },
  },
  {
    title: '退租状态',
    dataIndex: 'outState',
    scopedSlots: { customRender: 'status5' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const status1 = {
  0: {
    status: 'warning',
    text: '未进场',
  },
  1: {
    status: 'processing',
    text: '进场中',
  },
  2: {
    status: 'success',
    text: '已进场',
  },
}
const status2 = {
  0: {
    status: 'warning',
    text: '未装修',
  },
  1: {
    status: 'processing',
    text: '装修中',
  },
  2: {
    status: 'success',
    text: '已装修',
  },
}
const status3 = {
  0: {
    status: 'warning',
    text: '未验收',
  },
  1: {
    status: 'processing',
    text: '验收中',
  },
  2: {
    status: 'success',
    text: '已验收',
  },
}
const status4 = {
  0: {
    status: 'warning',
    text: '未开业',
  },
  1: {
    status: 'processing',
    text: '开业中',
  },
  2: {
    status: 'success',
    text: '已开业',
  },
}
const status5 = {
  0: {
    status: 'warning',
    text: '未退租',
  },
  1: {
    status: 'processing',
    text: '退租中',
  },
  2: {
    status: 'success',
    text: '已退租',
  },
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
import moment from 'moment'
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visible: false,
      filevisible: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      textlist: ['楼栋', '楼层', '铺位', '租户名称'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      params: {
        current: 1,
        pageSize: 10,
        'cc.name': {
          value: '',
          op: '%like%',
        },
        'cb.id': {
          value: undefined,
          op: '=',
        },
        'cf.id': {
          value: undefined,
          op: '=',
        },
        'cs.id': {
          value: undefined,
          op: '=',
        },
      },
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      total: 0,
      tenantlist: [],
      buildlist: '',
      floorlist: '',
      storelist: '',
      Enterpriselist: [],
      Clientlist: [],
      paperTypelist: [],
      agreement: [],
      Id: '',
      paperTypeId: '',
      paperTypeName: '',
      paperType: '',
      inFilestate: '0',
      decorateFilestate: '0',
      acceptFilestate: '0',
      openFilestate: '0',
      outFilestate: '0',
      files: [],
      list: undefined,
      inFiles: [],
      decorateFiles: [],
      acceptFiles: [],
      openFiles: [],
      outFiles: [],
      form: {
        id: '',
        form: {
          inFiles: '[]',
          decorateFiles: '[]',
          acceptFiles: '[]',
          openFiles: '[]',
          outFiles: '[]',
        },
        client: {
          name: '',
          infoRegLocation: '',
          content: '',
          type: '',
        },
        sign: {
          code: '',
          signBy: '',
          Date: [],
          signDate: '',
          belongTo: '',
          startDate: '',
          endDate: '',
          giveDate: '',
          openDate: '',
          partyAId: '',
          partyAName: '',
          partyAContact: '',
          partyAAddress: '',
          partyBId: '',
          partyBName: '',
          partyBContact: '',
          partyBAddress: '',
          projectId: '',
        },
        store: {
          name: '',
          totalArea: '',
          useArea: '',
          rentArea: '',
        },
        projectId: '',
      },
    }
  },
  filters: {
    stateFilter1(type) {
      if (type == null) {
        return ' '
      } else {
        return status1[type].text
      }
    },
    stateFilter2(type) {
      if (type == null) {
        return ' '
      } else {
        return status2[type].text
      }
    },
    stateFilter3(type) {
      if (type == null) {
        return ' '
      } else {
        return status3[type].text
      }
    },
    stateFilter4(type) {
      if (type == null) {
        return ' '
      } else {
        return status4[type].text
      }
    },
    stateFilter5(type) {
      if (type == null) {
        return ' '
      } else {
        return status5[type].text
      }
    },
    stateTypeFilter1(type) {
      if (type == null) {
        return ' '
      } else {
        return status1[type].status || ''
      }
    },
    stateTypeFilter2(type) {
      if (type == null) {
        return ' '
      } else {
        return status2[type].status || ''
      }
    },
    stateTypeFilter3(type) {
      if (type == null) {
        return ' '
      } else {
        return status3[type].status || ''
      }
    },
    stateTypeFilter4(type) {
      if (type == null) {
        return ' '
      } else {
        return status4[type].status || ''
      }
    },
    stateTypeFilter5(type) {
      if (type == null) {
        return ' '
      } else {
        return status5[type].status || ''
      }
    },
  },
  methods: {
    onfile() {
      this.getoperatetenantfiles(this.paperType, this.files)
    },
    //打开模态框
    onopen(type) {
      this.paperType = type
      this.filevisible = true
      this.paperTypeId = ''
      this.paperTypeName = ''
    },
    //改变状态
    handlestateChange(e) {
      let type = ''
      let state = ''
      if (e == 0) {
        type = 0
        state = this.inFilestate
      } else if (e == 1) {
        type = 1
        state = this.decorateFilestate
      } else if (e == 2) {
        type = 2
        state = this.acceptFilestate
      } else if (e == 3) {
        type = 3
        state = this.openFilestate
      } else if (e == 4) {
        type = 4
        state = this.outFilestate
      }
      this.getoperatetenantstate(type, state)
    },
    onparper(e) {
      let list = e.split('T')
      this.paperTypeId = list[0]
      this.paperTypeName = list[1]
    },
    ondelete(d, index, e) {
      //   console.log(e);
      this.getoperatedeletefiles(d, [e])
    },
    handleSearch(e) {
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['cc.name'].value = values.客户名称
      })
      this.getoperatetenant()
    },
    handleReset() {
      this.params['cb.id'].value = undefined
      this.params['cf.id'].value = undefined
      this.params['cs.id'].value = undefined
      this.fom.resetFields()
    },
    handlefile(info) {
      //   console.log(info);
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      //   console.log(info);
      this.putObject(
        this.paperType,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data,
        info.file.name
      )
    },
    async putObject(i, client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        // if (i == 0) {
        a.forEach(item => {
          this.files.push({
            bucket: 'sccncloud2',
            name: item.name,
            realName: realName,
            uid: id,
            status: 'done',
            paperTypeId: this.paperTypeId,
            paperTypeName: this.paperTypeName,
            url: url,
          })
        })

        this.list = undefined
        this.paperTypeId = ''
        this.paperTypeName = ''
        console.log(this.form.form)
      } catch (e) {
        console.log(e)
      }
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    onClose() {
      this.visible = false
      this.Id = ''
      this.form.form = {
        inFiles: '[]',
        decorateFiles: '[]',
        acceptFiles: '[]',
        openFiles: '[]',
        outFiles: '[]',
      }
      this.inFiles = []
      this.acceptFiles = []
      this.decorateFiles = []
      this.openFiles = []
      this.outFiles = []
    },
    onSubmit() {
      if (this.Id == '') {
        this.AddModule(this.form)
      } else if (this.Id != '') {
        this.putModule(this.form)
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.storeId
      this.visible = true
      this.getoperatetenantid(item.storeId)
    },
    onpagesize(e) {
      this.params.current = e
      this.getoperatetenant()
      // console.log(this.params.current);
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    async getoperatetenant() {
      try {
        const res = await http.getoperatetenant(this.params)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.tenantlist = data.tenants.records
          this.total = data.tenants.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getoperatetenantid(id) {
      try {
        const res = await http.getoperatetenantid(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form.form.inFiles = data.tenant.inFiles
          this.form.form.decorateFiles = data.tenant.decorateFiles
          this.form.form.acceptFiles = data.tenant.acceptFiles
          this.form.form.openFiles = data.tenant.openFiles
          this.form.form.outFiles = data.tenant.outFiles
          if (data.tenant.inFiles == null) {
            this.decorateFiles = []
          } else {
            JSON.parse(data.tenant.inFiles).forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              this.inFiles.push({
                bucket: 'sccncloud2',
                uid: nanoid(),
                name: item.name,
                realName: item.realName,
                status: 'done',
                paperTypeId: item.paperTypeId,
                paperTypeName: item.paperTypeName,
                url: url,
              })
            })
          }
          //   console.log(this.inFiles);
          if (data.tenant.decorateFiles == null) {
            this.decorateFiles = []
          } else {
            JSON.parse(data.tenant.decorateFiles).forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              this.decorateFiles.push({
                bucket: 'sccncloud2',
                name: item.name,
                realName: item.realName,
                paperTypeId: item.paperTypeId,
                paperTypeName: item.paperTypeName,
                url: url,
              })
            })
          }
          if (data.tenant.acceptFiles == null) {
            this.acceptFiles = []
          } else {
            JSON.parse(data.tenant.acceptFiles).forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              this.acceptFiles.push({
                bucket: 'sccncloud2',
                name: item.name,
                realName: item.realName,
                paperTypeId: item.paperTypeId,
                paperTypeName: item.paperTypeName,
                url: url,
              })
            })
          }
          if (data.tenant.openFiles == null) {
            this.acceptFiles = []
          } else {
            JSON.parse(data.tenant.openFiles).forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              this.openFiles.push({
                bucket: 'sccncloud2',
                name: item.name,
                realName: item.realName,
                paperTypeId: item.paperTypeId,
                paperTypeName: item.paperTypeName,
                url: url,
              })
            })
          }
          if (data.tenant.outFiles == null) {
            this.outFiles = []
          } else {
            JSON.parse(data.tenant.outFiles).forEach(item => {
              let url
              url = client.signatureUrl(item.name)
              this.outFiles.push({
                bucket: 'sccncloud2',
                name: item.name,
                realName: item.realName,
                paperTypeId: item.paperTypeId,
                paperTypeName: item.paperTypeName,
                url: url,
              })
            })
          }
          this.inFilestate = data.tenant.inState + ''
          this.decorateFilestate = data.tenant.decorateState + ''
          this.acceptFilestate = data.tenant.acceptState + ''
          this.openFilestate = data.tenant.openState + ''
          this.outFilestate = data.tenant.outState + ''
          this.form.client.name = data.tenant.client.name
          this.form.client.infoRegLocation = data.tenant.client.infoRegLocation
          data.tenant.client.contacts.forEach((item, d) => {
            if (d == 0) {
              this.form.client.content = item.mobile
            } else {
              this.form.client.content.join(item.mobile)
            }
          })
          this.form.client.type = data.tenant.client.type + ''
          this.form.sign = data.tenant.sign
          let date = []
          date.push(
            moment(data.tenant.sign.startDate),
            moment(data.tenant.sign.endDate)
          )
          this.form.sign.Date = date
          if (data.tenant.store == null) {
            return
          }
          this.form.store.name = data.tenant.store.name
          this.form.store.totalArea = data.tenant.store.totalArea
          this.form.store.useArea = data.tenant.store.useArea
          this.form.store.rentArea = data.tenant.store.rentArea
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getoperatepaperTypelist() {
      try {
        const res = await http.getoperatepaperTypelist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.paperTypelist = data.paperTypes
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getoperatetenantfiles(type, form) {
      try {
        const res = await http.getoperatetenantfiles(this.form.id, type, form)
        const { success, msg } = res.data
        if (success) {
          if (this.paperType == 0) {
            this.files.forEach(item => {
              this.inFiles.push(item)
            })
            this.form.form.inFiles = JSON.stringify(this.inFiles)
          } else if (this.paperType == 1) {
            this.files.forEach(item => {
              this.decorateFiles.push(item)
            })
            this.form.form.decorateFiles = JSON.stringify(this.decorateFiles)
          } else if (this.paperType == 2) {
            this.files.forEach(item => {
              this.acceptFiles.push(item)
            })
            this.form.form.acceptFiles = JSON.stringify(this.acceptFiles)
          } else if (this.paperType == 3) {
            this.files.forEach(item => {
              this.openFiles.push(item)
            })
            this.form.form.openFiles = JSON.stringify(this.openFiles)
          } else if (this.paperType == 4) {
            this.files.forEach(item => {
              this.outFiles.push(item)
            })
            this.form.form.outFiles = JSON.stringify(this.outFiles)
          }
          this.onfileclose()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getoperatedeletefiles(type, form) {
      try {
        const res = await http.getoperatedeletefiles(this.form.id, type, form)
        const { success, msg } = res.data
        if (success) {
          //   console.log(data);
          this.$message.warning('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getoperatetenantstate(type, state) {
      try {
        const res = await http.getoperatetenantstate(this.form.id, type, state)
        const { success, msg } = res.data
        if (success) {
          //   console.log(data);
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getoperatetenant()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
          if (data.buildings.length == 0) {
            this.floorlist = []
          } else {
            this.floorid(this.buildlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onfileclose() {
      this.filevisible = false
      this.files = []
      this.paperType = ''
    },
    //甲方
    async getenterpriselist() {
      try {
        const res = await http.getenterpriselist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Enterpriselist = data.enterprises
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    //乙方
    async getClientlist() {
      try {
        const res = await http.getClientlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Clientlist = data.clients
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          this.storetype(data.floors[0].id, 0)
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storetype(floorId, type) {
      try {
        const res = await http.storetype(floorId, type)
        const { success, data } = res.data
        if (success) {
          this.storelist = data.stores
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
  },
  mounted() {
    this.getsts()
    this.getoperatetenant()
    this.getoperatepaperTypelist()
    var users = JSON.parse(localStorage.getItem('store-userlist'))
    this.form.projectId = users.projectInfo.projectId
    this.buildingid(users.projectInfo.projectId)
    this.getenterpriselist()
    this.getClientlist()
  },
  computed: {},
}
</script>
<style lang="less" scoped>
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 557px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.text-store {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-btn {
  margin: 0px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.allcheck {
  position: absolute;
  top: -13px;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 50px;
  border-radius: 0;
  padding-left: 7px;
}
.content-body {
  padding: 24px;
  .ant-form-item {
    height: 90px;
    margin: 0;
    padding-right: 20px;
  }
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.ant--input {
  width: 25%;
}
.terms-input {
  width: 17%;
}
.content-body {
  padding: 24px;
  .body-building {
    line-height: 35px;
    min-width: 70px;
    height: 35px;
    text-align: center;
    border: 1px solid #999;
    position: relative;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px solid rgb(197, 172, 29);
  }
}
// .onclose() {
//   position: absolute;
//   top: 3px;
//   right: 3px;
// }
input {
  border-radius: 0;
}
.terms-option {
  width: 65px;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  right: 47px;
  cursor: pointer;
}
.div-div {
  width: 50%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 4px;
  border: 1px solid #d9d9d9;
}
.div-div:hover {
  border: 1px solid orange;
}
.div-hover {
  width: 50%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 4px;
  border: 1px solid orange;
}
.list-border {
  padding: 0 13px;
  width: 190px;
  margin-right: 8px;
  position: relative;
  height: 45px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.delete {
  position: absolute;
  top: 4px;
  right: 3px;
}
.yiban {
  background: #fff;
  margin-bottom: 24px;
}
.ant-select-enabled {
  width: 100px;
}
.aaa ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
}
.aaa ::v-deep .ant-input {
  border-left: none;
  border-right: none;
}
.aaa ::v-deep .ant-input-number {
  border-left: none;
  border-right: none;
}
::v-deep .ant-input-number {
  border-radius: 0;
}
.ccc ::v-deep .ant-input {
  border-right: none;
}
.ccc ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
}
.bbb ::v-deep .ant-select-selection--single {
  border-radius: 0;
  width: 100px;
  border-right: none;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-input {
  border-radius: 0;
}
.search-wrop-left {
  height: 450px;
  display: flex;
  flex-direction: row;
  border: 1px solid #d9d9d9;
  .search-left-project,
  .search-left-build,
  .search-left-floor {
    border-right: 1px solid #d9d9d9;
    min-width: 130px;
    width: 25%;
    height: 100%;
  }
  .search-bottom {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
  }
  .search-bottom:hover {
    background-color: #f5f5f5;
  }
  .search-left-top {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f0f1f4;
  }
  .search-left-stoor {
    min-width: 130px;
    width: 25%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
}
.state {
  width: 90px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
}
.content-body .price-formitem {
  position: relative;
}
.price-formitem {
  position: relative;
  width: 50%;
}
.back-search {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f5f5f5;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
</style>
